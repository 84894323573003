<template>
  <div>
    <div class="LLmain">
      <div class="LLlist">
        <div class="infinite-list" finished-text="没有更多了">
          <!-- @load="friendload" v-model="loadingfriend"
              :finished="finishedfriend"@load="gethuanzhelist"-->
          <div class="zdymain" v-if="list.length > 0">
            <div v-for="item in list" class="zdyline van-clearfix" :key="item.id" :class="{ act: toID == item.id }" @contextmenu.prevent="show(item, item.id)" style="height:72px;">
              <div @click="lookup(item, item.id, item.type, item.name, item.desc)" class="zdylineone">
                <div class="zdyuserimg newzdyuserimg">
                  <img :src="filterheadImg(item.headImg)" alt="" />
                </div>
                <div class="zdycustname newzdycustname">
                  <p class="custidline">
                    {{ item.custId }}
                  </p>
                  <p class="newcustname">
                    <span v-if="item.desc != '' && item.desc != null && item.desc != undefined">{{
                      item.desc
                    }}</span>
                    <span v-else-if="item.name != '' && item.name != null && item.name != undefined">{{ item.name | filtername(item.id) }}</span>
                    <span v-else>待完善用户{{ item.id.substr(item.id.length - 4) }}</span>
                  </p>
                  <p class="serviceline">
                    {{ item.serviceId }}
                  </p>
                </div>
                <div class="newtime">
                  <span class="msgfromwho">{{ filterfrom(item) }}消息 </span>
                  <span class="newtimeline">
                    <i style="display: inline-block">{{ item.sendTime | capitalize }}</i>
                    <i style="display: inline-block">{{ item.sendTime | capitalize2 }}</i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="talkmain" style="height:80vh;" v-if="dialogFormVisible">
        <div class="talkname">{{ talkname }}</div>
        <p class="lookhistory" v-if="lookmoreshow" @click="loadmore">
          查看历史记录
        </p>
        <div>
          <div class="talk-main" style="height:65vh;" id="scrolldIV1">
            <div class="talkline" v-for="(item, index) in talklist" :key="index">
              <div class="timeline">{{ item.sendTime }}</div>
              <div class="leftimg" v-if="item.from == toID">
                <img :src="userheadimg" alt="" />
              </div>
              <div class="talkleft" v-if="item.from == toID">
                <p style="margin: 0" v-if="item.type == '0'">
                  <span>
                    {{ item.content }}
                  </span>
                </p>
                <p style="margin: 0" v-if="item.type == '1'">
                  <span>
                    <img :src="item.content" alt="" @click="big(item.content)" />
                  </span>
                </p>
              </div>
              <div style="float:right;width:40px;height:60px;" v-if="item.from != toID">
                <div class="rightimg">
                  <img :src="female" alt="" />
                </div>
                <!-- <span style="font-size:12px;" v-if="item.from.substr(0, 1) == 's'">{{
                  item.from male
                }}</span> -->
              </div>

              <div class="talkright" v-if="item.from != toID">
                <span>
                  <i v-if="item.type == '0'"><b>{{ item.content }}</b></i>
                  <i v-if="item.type == '1'">
                    <img :src="item.content" alt="" @click="big(item.content)" />
                  </i>
                  <i v-if="item.type == 3">
                    <div style="width: 220px">
                      <p>寻医报名表</p>
                      <p>
                        您好，为了便于更好的帮您寻找擅长治疗您疾病的好医生，请点击对话框，详细填写报名表。
                      </p>
                      <p style="display: none"></p>
                    </div>
                  </i>
                  <i v-if="item.type == '2'">
                    <div style="width: 100px">
                      <p>问诊单:{{ JSON.parse(item.content).name }}</p>
                      <p>诊单编号:{{ JSON.parse(item.content).id }}</p>
                      <p @click="
                          EditZD = true;
                          EditMsg.kid = JSON.parse(item.content).id;
                        ">
                        查看详情>
                      </p>
                    </div>
                  </i>
                  <i v-if="item.type == 4">
                    <div style="width: 300px">
                      <p>精准匹配</p>
                      <div>
                        <div class="talkdocline" v-for="(it, index) in JSON.parse(item.content)" :key="index">
                          <img :src="it.headimg" alt="" />
                          <div class="wenzi">
                            <p class="wenzidocname">
                              {{ it.name }}<span>{{ it.rank }}</span>
                            </p>
                            <p class="wenzihospatil">{{ it.hospital }}</p>
                            <p class="wenzigoodat">
                              擅长：
                              <span>{{ it.goodat }}</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <p style="display: none"></p>
                    </div>
                  </i>
                  <i v-if="item.type == '5'">
                    <div style="width: 220px">
                      <p>寻医问诊表</p>
                      <p>
                        您好，为了便于帮您精准匹配好医生，请点击此对话框，详细填写寻医问诊表。
                      </p>
                      <p @click="
                          EditXY = true;
                          EditXYMsg.kid = JSON.parse(item.content).id;
                          EditXYMsg.noedit = true;
                        ">
                        查看详情>
                      </p>
                    </div>
                  </i>
                  <i v-if="item.type == '6'">
                    <div style="width: 220px">
                      <p>{{ JSON.parse(item.content).doc_name }}&nbsp;医生&nbsp;问诊单</p>
                      <p>
                        您好，为了让匹配的好医生详细了解您的病情信息，给出准确治疗方案，请点击此对话框，详细填写医生问诊单。
                      </p>
                      <p @click="
                          EditZD = true;
                          EditMsg.kid = JSON.parse(item.content).id;
                          EditMsg.noedit = true;
                        ">
                        查看详情>
                      </p>
                    </div>
                  </i>

                  <i v-if="item.type == '7'">
                    <div style="width: 220px">
                      <p>频道名:{{ JSON.parse(item.content).channel }}</p>
                      <p>ID:{{ JSON.parse(item.content).id }}</p>
                      <p @click="lookchanneldetail(JSON.parse(item.content))">
                        查看详情>
                      </p>
                    </div>
                  </i>
                  <i v-if="item.type == '8'">
                    <div style="width: 220px">
                      <p style="background: #ff8040">支付问诊费用</p>
                      <p>
                        您好，在线医助给您发来了支付问诊费用请求，点击开始支付。
                      </p>
                      <p @click="lookzfdetail(JSON.parse(item.content).id, 'zf')">
                        查看详情>
                      </p>
                    </div>
                  </i>
                  <i v-if="item.type == '9'">
                    <div style="width: 220px">
                      <p style="background: #ff8040">支付订单费用</p>
                      <p>订单ID:{{ JSON.parse(item.content).yforderno }}</p>
                      <p>ID:{{ JSON.parse(item.content).id }}</p>
                      <p @click="lookzfdetail(JSON.parse(item.content).id, 'dd')">
                        查看详情>
                      </p>
                    </div>
                  </i>
                  <i v-if="item.type == '10'">
                    <div style="width: 300px">
                      <p>好转病例</p>
                      <div class="talkdocline" style="border-bottom: none">
                        <img :src="JSON.parse(item.content).headimg" alt="" />
                        <div class="wenzi">
                          <p class="wenzidocname">
                            {{ JSON.parse(item.content).name
                            }}<span>{{ JSON.parse(item.content).ill_name }}</span>
                          </p>
                          <p class="wenzigoodat">
                            <span>{{ JSON.parse(item.content).des }}</span>
                          </p>
                        </div>
                      </div>
                      <p style="display: none"></p>
                    </div>
                  </i>
                  <i v-if="item.type == '11'">
                    <div style="width: 220px">
                      <p></p>
                      <p>
                        {{ JSON.parse(item.content).name }}给患者:{{
                          changetel(JSON.parse(item.content).id)
                        }}发送了留言短信。
                      </p>
                      <p></p>
                    </div>
                  </i>
                  <i v-if="item.type == '13'">
                    <div style="width: 220px">
                      <p>反馈表</p>
                      <p>
                        您好，为了便于您以后的治疗请认真填写反馈信息表。
                      </p>
                      <p @click="
                          lookfeedback = true;
                          feedbacknoedit = false;
                          feedbackid = JSON.parse(item.content).id;
                        ">
                        查看详情>
                      </p>
                    </div>
                  </i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 查看订单详情 -->
    <talklookorder :lookorder.sync="lookorder" :talkorderid="talkorderid" :talkordertype="talkordertype"></talklookorder>
    <!-- 查看订单详情 -->
    <!-- 编辑寻医弹窗开始 -->
    <el-dialog title="编辑寻医单" :modal="false" :visible.sync="EditXY" width="566px">
      <xypreview :kid="EditXYMsg.kid" :custid="EditXYMsg.custid" v-if="EditXY" :EditZD="EditXY" :noedit="false"></xypreview>
    </el-dialog>
    <!-- 编辑寻医弹窗结束 -->
    <!-- 编辑诊单开始 -->
    <el-dialog title="编辑诊单" :modal="false" :visible.sync="EditZD" width="566px">
      <preview :kid="EditMsg.kid" :custid="EditMsg.custid" v-if="EditZD" :EditZD="EditZD" :noedit="false"></preview>
    </el-dialog>
    <!-- 编辑诊单结束 -->
    <!-- 查看反馈表详情开始 -->
    <feedback :lookfeedback.sync="lookfeedback" :feedbackid="feedbackid" :feedbacknoedit="feedbacknoedit"></feedback>
    <!-- 查看反馈表详情结束 -->
  </div>
</template>
<script>
import "../assets/talkStyle.css";
import preview from "../components/preview.vue";
import xypreview from "../components/xypreview.vue";
import feedback from "../components/feedback.vue";
import talklookorder from "../components/talklookorder.vue";
var connection = "",
  _this = "",
  nowtouser = "";
export default {
  components: {
    preview,
    xypreview,
    feedback,
    talklookorder,
  },
  data() {
    return {
      list: [],
      dialogFormVisible: false,
      sid: "",
      kefuname: "",
      toID: "",
      female: require("../assets/female.png"),
      male: require("../assets/male.png"),
      talklist: [],
      userheadimg: "",
      lookmoreshow: false,
      indexedDBindex: 1,
      row: "",
      searchfiel: "",
      // loadmore: false,
      headimg: "",
      EditZD: false, //编辑诊单
      EditMsg: {
        kid: "",
        custid: "",
        noedit: false,
      },
      EditXY: false, //编辑寻医
      EditXYMsg: {
        kid: "",
        custid: "",
        noedit: false,
      },
      lookfeedback: false, //显示反馈表弹窗
      feedbackmsg: {}, //选择的反馈模板信息
      feedbackid: "", //反馈信息id
      feedbacknoedit: true, //反馈表是否可以编辑
      lookorder: false,
      talkorderid: "",
      talkordertype: "",
    };
  },
  filters: {
    capitalize: function (value) {
      if (value == "0001-01-01 00:00:00") return "";
      if (!value) return "";
      value = value.substr(0, 10);
      return value;
    },
    capitalize2: function (value) {
      if (value == "0001-01-01 00:00:00") return "";
      if (!value) return "";
      value = value.substr(10, value.length);
      return value;
    },
    filtername: function (value, id) {
      if (value.indexOf("dxhs_") == 0) {
        return "待完善用户" + id.substr(id.length - 4);
      } else {
        return value;
      }
    },
  },
  mounted() {
    _this = this;
    this.kefuname = sessionStorage.getItem("name"); //获取客服昵称
    this.sid = sessionStorage.getItem("id");
    /*获取客服头像*/
    if (sessionStorage.getItem("headimg")) {
      this.headimg = sessionStorage.getItem("headimg");
    } else {
      this.headimg = this.female;
    }
    connection = new signalR.HubConnectionBuilder()
      .withUrl(this.serveurl.serve + "/chathub")
      .build();
    // Transport fallback functionality is now built into start.
    connection
      .start()
      .then(function () {
        sessionStorage.setItem("logout", "");
        //链接成功时将用户名和连结ID发送至后台。
        var user = {
          id: "s" + _this.sid,
          headimg: sessionStorage.getItem("headimg"),
          name: _this.kefuname,
          type: Number(sessionStorage.getItem("stype")), //2
          noreadmessagecount: 0,
          connectionId: connection.connectionId,
        };
        connection.invoke("login", user);
      })
      .catch((error) => {
        // alert("jjj");
        console.error(error.message);
      });
    //Create a function that the hub can call to broadcast messages.
    connection.on("recieve", function (message, user) {
      console.log(message);
      console.log(user);
      if (user.type == "1" || user.type == "0") {
        if (_this.list.length > 0) {
          var hasElement = false;
          for (let b = 0; b < _this.list.length; b++) {
            if (_this.list[b].id == user.id) {
              _this.list[b].hasmsg = true;
              _this.list[b].sendTime = message.sendTime;
              _this.list[b].from = message.from;
              _this.list[b].to = message.to;
              hasElement = true;
            }
          }
          if (!hasElement) {
            user.hasmsg = true;
            user.sendTime = message.sendTime;
            user.from = message.from;
            user.to = message.to;
            _this.getBB(user).then((res) => {
              console.log(res);
              user.serviceId = res[0].data.result;
              user.custId = res[1].data.result[0].Cust_ID;
              _this.list.push(user);
            });
          }
        } else {
          user.hasmsg = true;
          user.sendTime = message.sendTime;
          user.from = message.from;
          user.to = message.to;
          _this.getBB(user).then((res) => {
            console.log(res);
            user.serviceId = res[0].data.result;
            user.custId = res[1].data.result[0].Cust_ID;
            _this.list.push(user);
          });
        }
      } else {
        _this.changelistfrom(message);
      }
      // Html encode display name and message.
      var encodedName = user.name;
      var encodedMsg = message.content;
    });
  },
  methods: {
    changelistfrom(message) {
      for (let a = 0; a < this.list.length; a++) {
        if (this.list[a].id == message.to) {
          this.list[a].from = message.from;
          this.list[a].to = message.to;
          this.list[a].sendTime = message.sendTime;
        }
      }
    },
    getBB(element) {
      var axiosList = [];
      var req = this.axios.get(
        this.serveurl.serve +
          "/api/User/user_belong_service?userID=" +
          element.id
      );
      var req1 = this.axios.post(
        "/gu/search_gu_custs",
        this.qs.stringify({ keyword: element.id, doc_id: "" })
      );
      axiosList.push(req);
      axiosList.push(req1);
      return this.axios.all(axiosList).then(
        this.axios.spread(function (...resList) {
          return resList;
        })
      );
    },
    //过滤头像
    filterheadImg(val) {
      var url = "";
      if (
        val == null ||
        val == "" ||
        val == undefined ||
        val == "null" ||
        val == "undefined"
      ) {
        url = this.male;
      } else {
        url = val;
      }
      return url;
    },
    //查看诊费详情
    lookzfdetail(id, type) {
      this.lookorder = true;
      this.talkorderid = id;
      this.talkordertype = type;
    },
    changetel(val) {
      var reg = /^(\d{3})\d*(\d{4})$/;
      var aaa = val.replace(reg, "$1****$2");
      return aaa;
    },
    //点开聊天窗口
    lookup(row, id, type, name, desc) {
      this.lookmoreshow = true;
      this.toID = id;
      if (id != nowtouser) {
        //判断是当前的聊天窗口还是新的聊天窗窗口，对聊天记录进行操作
        this.talklist = [];
      }

      this.gethistory(id,1).then((res) => {
        this.talklist = res;
        this.$nextTick(() => {
          var div = document.getElementById("scrolldIV1");
          div.scrollTop = div.scrollHeight;
        });
      });

      nowtouser = id;

      if (desc != "" && desc != null && desc != undefined) {
        this.talkname = desc + " " + row.custId;
      } else if (name != "" && name != null && name != undefined) {
        //展示聊天姓名
        if (name.indexOf("dxhs_") == 0) {
          this.talkname =
            "待完善用户" + id.substr(id.length - 4) + " " + row.custId;
        } else {
          this.talkname = name + " " + row.custId;
        }
      } else {
        this.talkname =
          "资料待完善用户" + id.substr(id.length - 4) + " " + row.custId;
      }

      row.hasmsg = false;
      this.searchfiel = id;
      this.dialogFormVisible = true;
      this.row = row;
      //设置头像
      if (
        row.headImg == "" ||
        row.headImg == null ||
        row.headImg == undefined ||
        row.headImg == "null" ||
        row.headImg == "undefined"
      ) {
        this.userheadimg = this.male;
      } else {
        this.userheadimg = row.headImg;
      }
    },
    //获取第一篇历史记录
    gethistory(id, page) {
      var list = this.axios
        .get(
          this.serveurl.serve +
            "/api/Message/get_user_history_message?id=" +
            id +
            "&index=" +
            page
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.indexedDBindex = 2;
            return res.data.result;
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
      return list;
    },
    loadmore() {
      this.axios
        .get(
          this.serveurl.serve +
            "/api/Message/get_user_history_message?id=" +
            this.toID +
            "&index=" +
            this.indexedDBindex
        )
        .then((res) => {
          if (res.data.code == 1) {
            this.indexedDBindex++;
            var newtalklist = this.talklistextend(res.data.result);
            var oldtalklist = this.talklist;
            this.talklist = [];
            this.talklist = newtalklist.concat(oldtalklist);
          }
        })
        .catch((error) => {
          console.error(error.message);
        });
    },
    //对数组转格式
    talklistextend(obj) {
      var arr = [];
      for (var i = 0; i < obj.length; i++) {
        arr.push(obj[i]);
      }
      arr = this.MsgSort(arr);
      return arr;
    },
    //根据时间排序
    MsgSort(obj) {
      obj.sort((a, b) => {
        let t1 = new Date(Date.parse(a.sendTime.replace(/-/g, "/")));
        let t2 = new Date(Date.parse(b.sendTime.replace(/-/g, "/")));
        return t1.getTime() - t2.getTime();
      });
      return obj;
    },
    //过滤从谁来的消息
    filterfrom(item) {
      var str = "";
      console.log(item);
      if (item.from.substr(0, 1) == "s") {
        str = "客服";
      } else {
        str = "患者";
      }
      return str;
    },
  },
};
</script>
<style>
.infinite-list {
  width: 320px;
}
/* .zdymain {
  border: none;
  border-bottom: 1px solid #ebeef5;
} */
.LLlist {
  height: 80vh;
  width: 350px;
  float: left;
}
.main-broad {
  min-height: 90vh;
}
.LLmain {
  min-height: 90vh;
}
.LLmain .talkmain {
  /* width: 75%; */
  width: 650px;
  float: left;
}
.LLmain .timeline {
  text-align: center;
}
.LLmain .lookhistory {
  text-align: center;
}
.LLlist .infinite-list {
  height: 80vh;
  width: 350px;
}
.LLlist .zdymain {
  height: 70vh;
  float: inherit;
  border: none;
}
.LLlist .zdyline {
  float: inherit;
}
#talktabs #lowerleveltab .el-tabs__header {
  width: auto;
  height: auto;
}
#talktabs #lowerleveltab .el-tabs__nav-scroll {
  background: none;
}
#talktabs #lowerleveltab .el-tabs__active-bar {
  width: 28px !important;
}
.zdylineone {
  cursor: pointer;
  height: 72px;
  float: left;
  width: 320px;
}
.newzdyuserimg {
  margin-top: 15px;
}
.newzdycustname {
  width: 180px;
  height: 72px;
  float: left;
}
.custidline {
  width: 100%;
  height: 20px;
  margin: 0;
  float: left;
  line-height: 20px;
  margin-top: 5px;
}
.newcustname {
  margin: 0px;
  height: 20px;
  float: left;
  line-height: 20px;
  width: 100%;
}
.newcustname span {
  width: 100%;
  display: inline-block;
  line-height: 14px;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.serviceline {
  width: 100%;
  height: 20px;
  margin: 0;
  float: left;
  line-height: 20px;
}
.newtime {
  width: 66px;
  height: 72px;
  float: left;
}
.msgfromwho {
  width: 100%;
  font-size: 12px;
  color: rgb(128, 128, 128);
  transform: scale(0.9);
  line-height: 20px;
  margin-top: 10px;
  text-align: right;
  display: block;
}
.newtimeline {
  width: 100%;
  float: left;
  font-size: 12px;
  color: rgb(128, 128, 128);
  transform: scale(0.8);
  line-height: 15px;
  text-align: right;
  display: block;
}
</style>
